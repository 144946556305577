/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Ignatius's Portfolio",
  description: "2nd year computer science student",
  og: {
    title: "Ignatius David Portfolio",
    type: "website",
    url: "http://ignatius.info/",
  },
};

//Home Page
const greeting = {
  title: "Ignatius David",
  logo_name: "Ignatius David",
  nickname: "Ig-nay-shus",
  subTitle:
    "A computer science student passionate about creating scalable systems, with a focus on building maintainable solutions that bridge the gap between developers and automated tasks.",
  resumeLink:
    "https://drive.google.com/drive/folders/1MCmGc9n3yqiJt0cA5OKwqGFqEm7_VOHS?usp=sharing",
  githubProfile: "https://github.com/Chary-x",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/Chary-x",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ignatius-boateng/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  /*
  {
    name: "YouTube",
    link: "https://www.youtube.com/@chary9407",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  */
  {
    name: "Gmail",
    link: "mailto:ignatiusboateng123@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];
/*
  {
    name: "Instagram",
    link: "https://www.instagram.com/ignatius.boa/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];
*/
const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⭐ Developing models for various deep learning and statistical use cases.",
        "⭐ Experience working on Computer Vision and Natural Language Processing (NLP) projects.",
        "⭐ Data aggregation through web scraping, automated data pipelines, and integration into predictive models, feeding high-quality, diverse data directly into machine learning systems.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⭐ Building responsive website frontends using React.js",
        "⭐ Developing mobile applications using React Native & Flutter",
        "⭐ Creating application backends in Node, Express, FastAPI, Flask & Springboot",
        "⭐ Storing data with SQL (SQLite3, PostgreSQL) and NoSQL (MongoDB, Firebase) databases",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B", // Flutter color
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:springboot",
          style: {
            color: "#6DB33F", // Spring Boot color
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "simple-icons:flask",
          style: {
            color: "#000000", // Flask color (black)
          },
        },
        {
          skillName: "FastAPI",
          fontAwesomeClassname: "simple-icons:fastapi",
          style: {
            color: "#009688", // FastAPI color (teal)
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⭐ Experience working on multiple cloud platforms",
        "⭐ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⭐ Deploying deep learning models on cloud to use on mobile devices",
        "⭐ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "SQLite",
          fontAwesomeClassname: "simple-icons:sqlite",
          style: {
            color: "#003B57", // SQLite color (dark blue)
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⭐ Designing highly attractive user interface for mobile and web applications",
        "⭐ Ensuring applications are accessible to all users, including those with disabilities, by adhering to best practices in accessibility (WCAG standards).",
        "⭐ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University Of Warwick",
      subtitle: "M.Eng in Computer Science",
      logo_path: "warwick.png",
      alt_name: "Univeristy Of Warwick",
      duration: "2023 - 2027",
      descriptions: [
        "⭐ Key modules include : Mathematics for Computer Scientists I & II, Data Structures & Algorithms, Data Analaytics, Cyber Securiuty",
        "⭐ Student researcher @ AI Society, member of CodeSoc, ACS and Prosper",
      ],
      website_link: "https://warwick.ac.uk/",
    },
    {
      title: "Notre Dame High School, Sheffield",
      subtitle: "A Levels : Computer Science, Physics, Mathematics",
      logo_path: "notredame.jpg",
      alt_name: "Notre Dame",
      duration: "2021 - 2023",
      descriptions: [
        "⭐ Recieved 70/70 for Computer Science NEA",
        "⭐ Recieved A Level Subject Award for Computer Science in reagrds to academic excellence",
        "⭐ Won gold at national indoor rowing competition (Teams of 3, 3000m)",
      ],
      website_link: "https://www.indiana.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Microsoft Embrace Mentee",
      subtitle: "Jack Joyce",
      logo_path: "microsoft.png",
      certificate_link:
        "https://www.linkedin.com/in/ignatius-boateng/details/experience/2481844969/multiple-media-viewer/?profileId=ACoAAD2-S7YBuplXAPE7jiZehnpaUkpy9Tp3I00&treasuryMediaId=1727120818328",
      alt_name: "Microsoft",
      color_code: "#ffff",
    },
    {
      title: "Software Engineering Job Simulation",
      subtitle: "JPMorgan",
      logo_path: "jpmorgan.jpg",
      certificate_link: "jpmforage.pdf",
      alt_name: "JPMorgan",
      color_code: "#00477b",
    },
    {
      title: "Web Dev Work Experience",
      subtitle: "Kainos",
      logo_path: "kainos.png",
      certificate_link:
        "https://www.linkedin.com/in/ignatius-boateng/details/experience/1635540710385/single-media-viewer/?profileId=ACoAAD2-S7YBuplXAPE7jiZehnpaUkpy9Tp3I00",
      alt_name: "Kainos",
      color_code: "#ffff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internships and Extracurriculars",
  description:
    "I've gained hands-on experience in cybersecurity through an internship and have been actively involved in software engineering across various insight days and smaller projects, where I developed and refined my technical skills in fast-paced, collaborative environments. Additionally, my freelance work in software development has enabled me to transform ideas into digital solutions, helping bring the visions of clients and collaborators to life.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Founding Engineer",
          company: "Vitae",
          company_url: "https:comingoutsoon",
          logo_path: "vitae.png",
          duration: "Jul 2024 - Present",
          location: "Coventry, UK",
          description: [
            "⭐ Vitae is an all-in-one platform that simplifies the global student application process, enabling users to discover and track opportunities seamlessly.",
            "⭐ Acting as a middleman between companies, students, and intermediary developers, we facilitate smooth communication and integration of job opportunities into the platform.",
            "⭐ Encouraged developers to integrate with our APIs by showcasing potential monetisation opportunities through seamless integration with their applications.",
          ],
          color: "#000000",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Incoming Software Engineering Intern",
          company: "Hubspot",
          company_url: "https://www.hubspot.com/",
          logo_path: "hubspot.png",
          duration: "Jul 2025 - Sep 2025",
          location: "London, UK",
          description: [
            "⭐ HubSpot is a leading CRM platform, powering millions of users with reliable and scalable infrastructure to help businesses grow better.",
            "⭐ As part of the infrastructure engineering team, we focus on building and optimising backend systems using Java, ensuring high availability, performance, and security across HubSpot’s global platform.",
            "⭐ We design and maintain scalable distributed systems, automate infrastructure processes, and solve complex challenges to support rapid product innovation.",
          ],
          color: "#000000",
        },
        {
          title: "Cyber Security Analyst",
          company: "Science Museum Group.",
          company_url: "https://www.sciencemuseumgroup.org.uk/",
          logo_path: "smg.jpg",
          duration: "Jul 2024 - Sep 2024",
          location: "York, United Kingdom",
          description: [
            "⭐ The Science Museum Group, renowned as the world’s leading science museum network, plays a pivotal role in fostering public engagement with science.",
            "⭐ As a Cyber Security Analyst Intern, I analysed security alerts, led discussions with senior colleagues, and presented impactful reports to enhance cybersecurity protocols across a network of over 2000 devices.",
            "⭐ Identified a gap in the auto-device patching system where certain software applications were not automatically updated and proposed an automated web scraping solution to alert IT solution engineers about available updates.",
            "⭐ Although the internship ended before I could develop the solution, my line manager expressed enthusiasm for my approach and indicated interest in employing me after university.",
          ],
          color: "#000000",
        },
      ],
    },
    {
      title: "Extracurriculars",
      experiences: [
        {
          title: "Google BGN Hackathon",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "11 Oct 2024 - 14 Oct 2024",
          location: "London, United Kingdom",
          description: [
            "⭐ Developed and deployed backend infrastructure using Firestore on Google Cloud for a Black history and culture AR mobile application",
            "⭐ Integrated Google Maps and Places APIs to display global markers for Black historical monuments and cultural landmarks.",
            "⭐ Utilised Gemini AI to generate educational briefs for landmarks, targeting children and schools to enhance learning experiences.",
            "⭐ Implemented event setup and navigation features, allowing users to visit landmarks and earn 'CulturePoints' by utilising the Haverian algorithm through a gamified experience (like Pokemon Go).                                    ",
            "⭐ Set up bundler build scripts to streamline and optimise the development workflow for the team, ensuring seamless deployment",
          ],
          color: "#4285F4",
        },
        {
          title: "Software Engineering Accelerator",
          company: "Bloomberg",
          company_url: "https://www.bloomberg.com/company/",
          logo_path: "bloomberg.png",
          duration: "4th Oct 2024",
          location: "London, United Kingdom",
          description: [
            "⭐ Participated in grouped programming challenges given by bloomberg engineers.",
            "⭐ Conducted mock interviews with Bloomberg engineers, gaining valuable insights into technical interview processes.",
          ],
          color: "#4285F4",
        },
        {
          title: "Insight Into Quant Analytics",
          company: "JPMorgan",
          company_url: "https://www.jpmorgan.com",
          logo_path: "jpmorgan.jpg",
          duration: "3rd Oct 2024",
          location: "London, United Kingdom",
          description: [
            "⭐ Participated in a guest panel discussion with experienced quantitative analysts.",
            "⭐ Networked with recruiters and professionals, helping me build connections and understand the roadmap for pursuing a quant career at the firm.",
          ],
          color: "#4285F4",
        },
        {
          title: "Engineering Tech Lab",
          company: "Bloomberg",
          company_url: "https://www.bloomberg.com/company/",
          logo_path: "bloomberg.png",
          duration: "15 Nov 2023 - 16th Nov 2023",
          location: "London, United Kingdom",
          description: [
            "⭐ Attended a 2-day intensive lab at Bloomberg's London office, accommodated by Bloomberg hotels, where I worked closely with their engineering team.",
            "⭐ Collaborated with Bloomberg engineers to build a Python based portfolio manager.",
            "⭐ Participated in a mock interview with a Senior Software Engineer",
          ],
          color: "#4285F4",
        },
        {
          title: "Embrace Mentee",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/en-gb",
          logo_path: "microsoft.png",
          duration: "Feb 2023 - Mar 2023",
          location: "Remote",
          description: [
            "⭐ Attended training workshops focused on enhancing application success during recruitment season.",
            "⭐ Worked with mentor Jack Joyce, who prepared me for technical interviews and introduced me to SIEM solutions, which helped during my Cyber Security Internship the following summer.",
            "⭐ Connected with key engineers in safety-critical systems through Jack",
          ],
          color: "#4285F4",
        },
        {
          title: "Mentee, Scholar & Mentor",
          company: "Zero Gravity",
          company_url: "https://www.bloomberg.com/company/",
          logo_path: "zerogravity.jpg",
          duration: "Jun 2022 - Present",
          location: "Remote",
          description: [
            "⭐ Received mentorship and academic support to navigate A-levels and secure a scholarship for academic excellence.",
            "⭐ Transitioned from mentee to mentor, providing guidance on university applications, career choices, and academic strategies.",
            "⭐ Contributed to the Zero Gravity mission by supporting students in achieving their ambitions.",
          ],
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Public Projects",
  description:
    "They say that creating projects is the best way to learn. Here are some of my public repositories. While I enjoy sharing my work, some repositories remain private as they are still actively being used and developed. These will be made public once they are no longer in use.",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, (Future) Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "cbi",
      name:
        "Mind Meets Machine: The Synergy of CBIs, VR, and AI. A Deep Dive into CBIs, VR, and AI Integration",
      createdAt: "COMING SOON",
      description: "A Deep Dive into CBIs, VR, and AI Integration",
    },
    {
      id: "nea",
      name: "Terrabyte - How was it made?",
      createdAt: "2023-04-19T00:00:00Z",
      description:
        "How to secure full marks in your A Level Computer Science NEA",
      url:
        "https://drive.google.com/drive/folders/1JXgVnzO5nptWh0yBqO28mdXTW0JciU1G?usp=sharing",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "me.jpg",
    description:
      "I am available on almost every social media. You can message me, and I will reply within 24 hours. I'm always happy to discuss app ideas, let me bring your ideas to reality!",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "As I progress in my professional career, I will document future development processes, challenges faced, and the solutions implemented.",
    link: "coming soon",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Seventia, Coventry, UK",
    locality: "San Jose",
    country: "UK",
    region: "Coventry",
    postalCode: "95129",
    streetAddress: "Seventia",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
